import React from "react"
import SocialLinks from "../constants/socialLinks"
const Footer = () => {
  return (
    <footer className="footer">
      <SocialLinks styleClass="footer-links" />
      <h4>&copy; {new Date().getFullYear()} Bibo Chan. All rights reserved.</h4>
    </footer>
  )
}

export default Footer
