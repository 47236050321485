import React, { useEffect, useState } from "react"
// import logo from "../assets/logo.svg"
import { FaHamburger, FaCode } from "react-icons/fa"
import { Link } from "gatsby"
import PageLinks from "../constants/links"

const Navbar = ({ toggleSidebar }) => {
  // determined if page has scrolled and if the view is on mobile
  const [scrolled, setScrolled] = useState(false)

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10
      if (isScrolled !== scrolled) {
        setScrolled(!scrolled)
      }
    }

    document.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener("scroll", handleScroll)
    }
  }, [scrolled])

  return (
    <nav className={scrolled ? "navbar navbar-active" : "navbar"}>
      <div className="nav-center">
        <div className="nav-header">
          {/* <img src={logo} alt="logo" /> */}
          <Link to={"/"}>
            <FaCode className="logo-icon" />
          </Link>
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <FaHamburger></FaHamburger>
          </button>
        </div>
        <PageLinks styleClass="nav-links"></PageLinks>
      </div>
    </nav>
  )
}

export default Navbar
